/* Sidebar styles */
.sidebar {
    position: fixed;
    top: 0;
    left: -250px; /* Sidebar hidden by default */
    width: 250px;
    height: 100%;
    background-color: #333;
    color: white;
    transition: left 0.3s ease-in-out;
    z-index: 999;
  }
  
  .sidebar.open {
    left: 0; /* Sidebar is visible */
  }
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }
  
  .logo img {
    width: 120px;
  }
  
  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  .sidebar-nav ul {
    padding: 0;
    margin-top: 30px;
    list-style: none;
  }
  
  .sidebar-nav ul li {
    margin-bottom: 15px;
  }
  
  .sidebar-nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
  }
  
  /* Sidebar Overlay */
  .sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 998;
  }
  
  .sidebar-overlay.active {
    display: block; /* Show overlay when sidebar is open */
  }
  
  /* Header styles */
  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .header-logo img {
    width: 120px;
  }
  
  .menu-toggle {
    background: none;
    border: none;
    font-size: 30px;
    color: #333;
    cursor: pointer;
  }
  
  .menu-toggle i {
    font-size: 24px;
  }
  